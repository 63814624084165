*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.shop-header{
    padding-top: 5rem;
    padding: 5rem 4rem 1rem 4rem;
    color:#646568;
    border-bottom: 1px solid rgb(49, 48, 48);
    
}

.shop-items{
    display: grid;
    grid-template-columns: repeat( auto-fill, minmax(330px, 1fr) );
    grid-template-rows: repeat( auto-fill, minmax(400px, 1fr) );
    gap: 3rem;
    padding: 2rem;
    background: #FFFFFF;
    overflow-y: auto;
    height: 80vh;
    
}
.shop-item{
    margin: 1rem;
    flex: 0 1 100px;
    box-sizing: border-box;
    box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.14);
    border-radius: 5px;
    padding: .5rem;
    box-shadow: 0px 2px 18px 0px #00000024;
}
.shop-item-txt{
	padding: 1rem;
}
.shop-viewMore{
    display: flex;
    flex-wrap: wrap;
    padding: 2rem;
    background: #FFFFFF;
}
.description{
    display: flex;
    justify-content: space-between;
    padding: .5rem 0;
}
.description p{
    max-width: 200px;
    font-size: 13px;
    color: rgb(74, 73, 73);
}
.description button{
    outline: none;
    border: none;
    background: transparent;
}
.description >div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}
.add-icon{
    width: 40px;
    height: 40px;
    color: #4D568F;
}
.item-img-div {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 200px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.shop-viewMore button{
    margin: auto;
    border: none;
    color: white;
    background: #646568;
    padding: .5rem 1rem;
    border-radius: 5px;
}
.description{
	display: flex;
	justify-content: space-between;
}
.description p{
	width: 90%;
}
.description button{
	border-radius: 50%;
	width: 2rem;
	height: 2rem;
	outline: none;
	color: white;
	font-size: 1.3rem;
	cursor: pointer;
}
.item-img{
    flex-shrink: 1;
    min-width: 100%;
    width: 100%;
    height: 200px;
}