* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.flight-header-div {
  padding-top: 10rem;
  padding-bottom: 10rem;
  display: grid;
  place-items: center;
  background: url(../assets/images/letstravel.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.flight-header {
  text-align: center;
  /* padding: 2rem; */
}
.flight-header h4 {
  padding-top: 3rem;
  font-size: 250%;
  line-height: 120%;
  color: white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.flight-header p {
  font-size: 120%;
  margin-top: 1rem;
  color: white;
  font-style: italic;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-bottom: 1rem;
}
/* .flight-header button {
  background: #2f2f30;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  border: none;
  outline: none;
} */
.openModalBtn {
  background: #2f2f30;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  border: none;
  outline: none;
}

/* .flight-header button:hover {
  background: #646568;
} */
.how-it-works {
  text-align: right;
  padding: 2rem 2rem 0.5rem 0;
}

/* --------------------------------------------- */

.flight-booking {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  justify-content: space-between;
  margin-bottom: 5rem;
  overflow: hidden;
}
.lift {
  flex: 1 1 250px;
  margin: 1rem;
}
.flight-booking-text {
  padding: 0rem 1rem;
  width: 600px;
  min-width: 300px;
}
.flight-booking-text h4 {
  font-size: 35px;
  margin-bottom: 1.5rem;
}
.flight-booking-text p {
  padding-bottom: 2rem;
}
.flight-booking-img {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 500px;
  height: 300px;
  margin: 1rem;
}
.flight-booking-img img {
  flex-shrink: 0.5;
  min-width: 100%;
  min-height: 100%;
}

.support {
  text-align: right;
}

.flight-quote {
  display: grid;
  place-items: center;
  padding: 0.5rem 1rem 3rem;
}
.flight-quote p {
  text-align: center;
  font-style: italic;
  padding: 0.5rem;
  font-weight: 500;
}

/* ------FLIGHT SELECTOR------------------------------------- */

.title-question {
  font-size: 25px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 4rem;
  color: white;
  font-weight: 700;
}

.location-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.location-area input {
  margin: 0.5rem;
  /* background-color: rgba(0, 0, 0, 0.373); */
}

.flight-header-div input {
  height: 100%;
  width: 100%;
}

.tab {
  height: 2.5rem;
  width: 12rem;
  position: relative;
  border: 1px solid grey;
  border-radius: 10px;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem;
  font-size: 0.88rem;
}

.tab input {
  height: 100%;
  width: 100%;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 10px;
  margin: 0;
  padding: 0.5rem;
}

.guest {
  height: 2.5rem;
  width: 18rem;
  background-color: white;
  position: relative;
}

.guestrow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}
.addguestrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addguestrow span:first-child {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.addguestrow span:nth-child(2) {
  margin: 0 0.8rem;
}
.addguestrow span:last-child {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.drop {
  box-shadow: 0px 3px 25px #adaaaa29;
  z-index: 2000;
  border-radius: 10px;
  padding: 1rem;
  min-width: 18.75rem;
  width: 100%;
  background-color: white;
  position: absolute;
  top: 2.5rem;
}
.locationdrop {
  min-height: 17.5rem;
}
.guestdrop {
  min-height: 17.5rem;
}

.tabcalenderdrop {
  box-shadow: 0px 3px 25px #adaaaa29;
}

.react-calendar {
  width: 100% !important;
  border: none !important;
}

.tabcalenderdrop .react-calendar__month-view__weekdays {
  display: flex !important;
  margin: 1rem 0;
}

.tabcalenderdrop .react-calendar__navigation button {
  background-color: white;
}

.tabcalenderdrop .react-calendar__month-view__weekdays__weekday {
  font-size: 0.875rem;
  color: #707070;
  font-weight: normal;
  line-height: 1.313rem;
  text-decoration: none;
}

.tabcalenderdrop .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  text-transform: capitalize;
}

.tabcalenderdrop .react-calendar__navigation button {
  border-radius: 50%;
}

.tabcalenderdrop .react-calendar__navigation {
  width: 100% !important;
  display: flex !important;
  justify-content: center;
}

.tabcalenderdrop .react-calendar__month-view__weekdays {
  color: #212121;
}

.tabcalenderdrop .react-calendar__navigation button {
  flex-grow: unset !important;
  font-size: 0.875rem;
  color: #212121;
  font-weight: bold;
  background-color: #fff;
  border: none;
  outline: none;
  margin: 0 0.8rem;
}

.tabcalenderdrop .react-calendar__navigation {
  margin-bottom: 0;
}

.tabcalenderdrop .react-calendar__navigation__prev-button {
  border: 1px solid #f4f4f6 !important;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.tabcalenderdrop .react-calendar__navigation__next-button {
  border: 1px solid #f4f4f6 !important;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.tabcalenderdrop .react-calendar__tile {
  font-size: 0.75rem !important;
  color: #212121 !important;
  height: 2rem;
  width: 2rem;
  text-align: center;
  border-radius: 5px;
  outline: none;
  border: none;
}

.tabcalenderdrop .react-calendar__tile:disabled {
  border-radius: 0px;
}

.tabcalenderdrop .react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
  font-size: 0.75rem !important;
}

.tabcalenderdrop .react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #212121;
  color: #fff !important;
  border-radius: 5px;
}

.tabcalenderdrop .react-calendar__tile--active {
  background: #212121;
  color: #fff !important;
  border-radius: 5px;
}

.tabcalenderdrop .react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #fff;
}

.tabcalenderdrop .react-calendar__tile--now:hover {
  color: #212121 !important;
  background: #f4f4f6 !important;
}

.tabcalenderdrop .react-calendar__tile--now {
  color: #212121 !important;
  background: #f6f6f8 !important;
}

.tabcalenderdrop .react-calendar__tile--now:hover.react-calendar__tile--active,
.react-calendar__tile--now:focus {
  color: #fff !important;
  background: #212121 !important;
}
