@import "rsuite/dist/rsuite.css";
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    font-family: 'Poppins', sans-serif;
}
button{
    font-family: "Poppins";
    cursor: pointer;
}

::-webkit-scrollbar{
    width: 10px;
  }
  
  ::-webkit-scrollbar-track{
    background: rgb(33, 33, 33);;
  }
  
  ::-webkit-scrollbar-thumb{
    background: #5a595b;
  }
  ::-webkit-scrollbar-thumb:hover{
    background: #414041;
  }
  



.header{
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}
.header-content{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100vw;
    padding: 0;
    /* border: 2px solid red; */
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    display:flex;
    align-items: center;

}
.headerimg1{
    background-image: url("../src/assets/images/test3.jpg");

}
.headerimg2{
    background-image: url("../src/assets/images/test2.jpg");

}
.headerimg3{
    background-image: url("../src/assets/images/letstravel.jpg");

}
.header-content h3{
    font-size: 80px;
    max-width: 660px;
    padding-top: 2%;
    line-height: 120%;
    font-family: 'Poppins';
    text-shadow: 2px 2px 10px #414041c8;
}

/* -----------------------------------------kk- */
.nav{
    padding: .8rem 2rem;
    background: rgb(49, 48, 48);
    background: rgb(33, 33, 33);
    position: fixed;
    width: 100vw;
    z-index: 4;
}
.nav-links{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
}
.logo-text h3{
    color: white;
    text-decoration: none;
}
.logo-text-link{
    text-decoration: none;
    color: white;
}
.logo-text-link:hover{
    text-decoration: none;
    color: white;
}
.link-items{
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}
.link-items a, li{
    color: rgba(255, 255, 255, 0.741);
    text-decoration: none;
    list-style: none;
    margin: 0 .5rem;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1.7px;
}
li{
    position: relative;
}
.link-items a:hover{
    color: white;
}
li:after{
    content: "";
    height: 2px;
    width: 0;
    background: #8bc6e4;
    position: absolute;
    bottom: -7px;
    left: 0;
    transition: 0.3s;
    
}
li:hover:after{
    width: 100%;
}
.cart-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-size: 15px;
}
.nav-icon{
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 4;
}

.menu-icon{
    display: none;
}

/* ------------------------------------------ */

.header-content{
    font-size: 5rem;
    max-width: 900px;
    color: white;
    padding: 12rem 4rem 8rem;
    bottom: 0;
}

/* ------------------------------------------------ */
#about{
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.about-content h4{
    font-size: 1.5rem;
    color: black;
}
.about-content p {
    max-width: 880px;
    font-size: 15px;
    padding: 2rem;
    letter-spacing: 4%;
}
.about-content button{
    background: none;
    border: none;
    font-weight: 600;
    border-bottom: 1px solid black;
}

/* ------------------------------------------ */
#services{
    /* height: 60vh; */
    margin-top: 1%;
    margin-bottom: 10%;
    padding: 1rem 3rem;
    
}

.services-content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.service{
    flex: 1 1 150px;
    height: 20rem;
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin: 1rem;
    box-shadow: -1px 1px 25px -4px #C4C4C4;
    border-radius: 6px;
    transition: .5s ease;
    color: black;
}
.service:hover{
    transform: scale(0.9);
    transition: .5s ease;
    background: #8bc6e4;
    background: rgba(33, 33, 33, 0.864);
    color: white;
    cursor: pointer;
}

.service-icon{
    height: 50px;
    width: 50px;
    margin-top: 2rem;
}
.service p{
    text-align: center;
    font-size: 14px;
    padding: 2rem .5rem;
    max-width: 350px;
}
.service h4{
    letter-spacing: 1px;
    
}

.flight button{
    margin-top: 2rem;
}

.common-btn{
    border: none;
    color: white;
    background:rgb(53, 53, 53);;
    padding: .6rem 2rem;
    border-radius: 3px;
    position: relative;
}
.common-btn:hover{
    background: black;
    transition: 0.5s;
}
/* --------------------------------------------- */

.choose-us{
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    justify-content: space-between;
    margin-bottom: 5rem;
    overflow: hidden;
}
.check-service{
    color: #8bc6e4; 
    height: 20px;
    width: 20px;
    margin-right: 1rem;
}

.lift{
    flex: 1 1 250px;
    margin: 1rem;
}
.choose-us-text{
    padding: 0rem 1rem;
    width: 600px;
    min-width: 300px;
}
.choose-us-text h4{
    font-size: 35px;
    margin-bottom: 1.5rem;
    position: relative;
    color: black;
}
.choose-us-text h4::before{
    content: " ";
    height: .8rem;
    width: 10rem;
    background: #8bc6e4;
    position: absolute;
    z-index: -1;
    top: 30px;
}
.choose-us-text p{
    padding-bottom: 2rem;
}
.choose-us-img{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 400px;
    max-height: 500px;
    margin:1rem;
    transition: .5s ease-in-out;
    transform: scale(0.9);
}
.choose-us-img:hover{
    transform: scale(1);
    transition: .5s ease-in-out;  
}
.choose-us-img img{
    flex-shrink: 1;
    min-width: 100%;
    min-height: 100%;
}

.support{
    text-align: right;
}


#contact{
    background-color: #efefef;
    overflow: hidden;
}
.form-text{
    display: grid;
    place-items: left;
    padding-top: 4rem;
}
.form-text h4{
    font-size: 30px;
    font-weight: bold;
    margin-left: 3rem;
    color: #222223;
}

.contact-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: .2rem 1.5rem 3rem;
}
.contact-box{
    flex: 1 1 300px;
    margin: 1rem;

}
.contact-form{
    /* border: 2px solid red; */
    padding: 0rem 1rem 0rem 0rem;
}
.contact-form form{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    color: white;
    color: black;
    width: 100%;
    height: 100%;
}
.contact-input{
    height: 3.5rem;
    width: 100%;
    padding: 1rem;
    margin: .5rem 0;
    border: none;
    color: white;
    color: black;
    outline: none;
    background: #0000004a;
    border: 1px solid black;
    background: transparent;
    border-radius: 3px;
    font-size: 15px;
    font-family: 'Poppins';  
}
.contact-area{
    height: 10rem;
    width: 100%;
    padding: 1rem;
    margin: .5rem 0;
    border: none;
    color: white;
    color: black;
    outline: none;
    background: #0000004a;
    border: 1px solid black;
    background: transparent;
    border-radius: 3px;
    font-size: 15px;
    font-family: 'Poppins'; 
}
::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.391);
}

.contact-form button{
    width: 40%;
    height: 50px;
    margin-top: .3rem;
    font-size: 15px;
    font-family: 'Poppins';
    margin-left: 0;
}

.contact-map{
    background-color: rgb(160, 150, 150);
    width: 45%;
    height: auto;
}
.google-map > div{
    height: 440px !important;
}
#footer{
    padding: 3rem 1rem 1rem;
    background: rgb(33, 33, 33);
}
.chap-footer-logo{
    color: white !important;
}

.footer-links{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.box{
    flex: 1 1 150px;
    margin: 1rem;
    color: rgba(211, 210, 210, 0.83);
}
.footer-links p{
    font-size: 15px;
    padding-top: .3rem;
    font-weight: 300;
}
.footer-links h4{
    padding-bottom: 1rem;
    color: white;
}

.footer-info{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 3.5rem 0rem 0rem;
}
.footer-info p{
    font-size: 10px;
    font-weight: 300;
    margin-top: 0;
}

.call-icon{
    color: #9EA8E9;
    color: #8bc6e4;
    height: 15px;
    width: 15px;
}
.call-now a{
    color: white;
}
.footer-socials{
    display: flex;
}
.icon{
    color:#8bc6e4 ;
    height: 25px;
    width: 25px;
}

/* rs number input */
.rs-input-number-btn-group-vertical {
    display: none;
}

.trip-type {
    margin-top: 20px;
    margin-bottom: 10px;
}

.input-title {
    margin-bottom: 10px;
}

.user-cred {
    margin-top: 20px;
}
/* .rs-form-vertical .rs-form-group .rs-input-group {
    width: 150px;
} */



/* RESPONSIVE DESIGN ------------------------------------------------*/

@media screen and (max-width: 1280px){
    /* .service{
        height: 18rem;
        width: 18rem;
    } */
    
    .network-img-div{
        width: 40%;
        height: auto;
    }
    
    .network-img-div img{
        flex-shrink: 5;
    }
    
    .contact-form{
        margin-bottom: 2rem;
    }
    form input{
        width: 300px;
        padding: 1rem;
        margin: .3rem 0;
    }
   
}

@media screen and (max-width: 980px){
    /* .service{
        width: 16rem;
    } */
    .header{
        height: 70vh;
        background-position: center;
    }
    .header-content h3{
        font-size: 70px;
        max-width: 500px;
    }
}

@media screen and (max-width: 720px){
    .menu-icon{
        display: inline;
    }    

    .link-items{
        position: fixed;
        background: rgb(49, 48, 48);
        height: 90vh;
        width: 100%;
        flex-direction: column;
        clip-path: circle(100px at 90% -20%);
        -webkit-clip-path:circle(100px at 90% -20%);
        transition: all 1s ease-out;
        pointer-events: none;
        padding-top: 1rem;
        top: 4rem;
      }
    .link-items.open{
        clip-path: circle(1000px at 50% 0%);
        -webkit-clip-path:circle(1000px at 50% 0%);
        pointer-events: all;
    }
/* THE ABOVE WAS THE MENU BAR ANIMATION */

    .header-content{
        font-size: 4.5rem;
        padding: 15rem 4rem 8rem;
    }
    .about-content p {
        max-width: 600px;
    }
    #network{
        padding: 2rem 2rem;
    }
    .network-content{
        max-width: 22rem;
        padding: 2rem;
    }
    .network-content h4{
        font-size: 20px;
    }
    .network-img-div{
        width: 250px;
        height: 250px;
    }
    .network-img-div img{
        flex-shrink: 20;
    }
    .header-content h3{
        font-size: 70px;
        max-width: 450px;
        /* padding-top: 2%;
        line-height: 120%;
        font-family: 'Poppins';
        text-shadow: 2px 2px 10px #414041c8; */
    }
}

@media screen and (max-width: 650px){
    .header{
        height: auto;
    }
    .header-content{
        font-size: 3.5rem;
        padding: 13rem 2rem 8rem;
    }
    #services{
        height: auto;
    }
    /* .services-content{
        flex-direction: column;
    }
    .service{
        height: 20rem;
        width: 20rem;
        margin: 2rem 0;
    } */
    #network{
        flex-direction: column;
        align-items: center;
        padding: 2rem 3rem;
    }
    .network-content{
        margin: 0;
        text-align: center;
        max-width: 700px;
    }
    .network-content p{
        margin-bottom: 1.5rem;
    }
    .network-img-div{
        height: 200px;
        width: 350px;
        margin-bottom: 2rem;
    }
    .net-div{
        display: none;
    }
    .network-img-div img{
        flex-shrink: 0;
    }
    .flight-btn{
        margin-bottom: 2rem;
    }
    .contact-map{
        width: 100%;
    }
    .header-content h3{
        font-size: 60px;
        max-width: 400px;
        /* padding-top: 2%;
        line-height: 120%;
        font-family: 'Poppins';
        text-shadow: 2px 2px 10px #414041c8; */
    }

}
@media screen and (max-width: 600px){
    .header-content h3{
        font-size: 60px;
        max-width: 300px;
    }

}

@media screen and (max-width: 390px){
    .nav{
        padding: 1.5rem 2rem;
    }
    .header{
        height: 70vh;
        background-position: center;
    }
    .header-content{
        font-size: 3rem;
        padding: 12rem 2rem 8rem;
    }
  
    .about-content p {
        font-size: 14px;
    }

    /* .service{
        height: 18rem;
        width: 18rem;
        padding: 1rem;
    } */
    #network{
        padding: 2rem 1.5rem;
    }
    .network-img-div{
        height: 200px;
        width: 280px;
       
    }
    .network-img-div img{
        flex-shrink: 5;
        min-width: 100%;
        min-height: 100%
    }
    .flight-btn{
        margin-bottom: 2rem;
    }
    .header-content h3{
        font-size: 40px;
        max-width: 200px;
    }
    
}

