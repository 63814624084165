#entertainment-page{
    padding-top: 4.5rem;
    color: white;
    overflow: hidden;
}

.entertainment-header{
    /* height: 75vh; */
    background: url(../assets/images/img1.jpg);
    background-position: center;
    background-size: cover;
    padding: 2rem 0;
}

.entertain-container{
    padding: 3rem 0 1rem 3rem;

}

.entertainment-header h4{
    color: white;
    font-size: 65px;
    max-width: 600px;
    line-height: 120%;

}
.entertainment-header p{
    color: rgba(255, 255, 255, 0.762);
    font-size: 15px;
    margin: .5rem 0 ;
    max-width: 300px;
}
.entertainment-header button{
    background: rgba(0, 0, 0, 0.69);
    font-size: 15px;
    color: white;
    padding: .5rem 1rem;
    border: none;
    border-radius: 7px;
    margin-top: 1rem;
}
.entertainment-header button:hover{
    background: rgba(0, 0, 0, 0.838);
    cursor: pointer;
}

.choose-plan{
    padding: 4rem 2.5rem;
    background-color: rgba(27, 26, 26, 0.76) ;
}
.sub-pack{
    width: 80vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}
.sub-pack img{
    flex-shrink: 1;
    min-width: 100%;
    min-height: 100%
}

.choose-plan h6{
    color: white;
    font-size: 30px;
    margin-bottom: 1rem;
}

.paydstv{
    padding: 5rem 2.5rem;
    display: flex;
    justify-content: space-between;
    background-color: rgba(27, 26, 26, 0.76) ;
    display: flex;
    flex-wrap: wrap;
    /* padding: 1rem; */
    justify-content: space-between;
    margin-bottom: 5rem;
    overflow: hidden;
}
.how-to-pay-img{
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}
.how-to-pay-img img{
    flex-shrink: 1;
    min-width: 100%;
    min-height: 100%
}
.how-to-pay-dstv h6{
    font-size: 30px;
    line-height: 120%;
}
.payment-steps{
    color: rgba(255, 255, 255, 0.762);
    margin-top: 2rem;
    line-height: 3rem;
    font-size: 15px;
}
/* RESPONSIVE DESIGN ------------------------------------------ */

@media screen and (max-width: 1280px){
   
}

@media screen and (max-width: 980px){

}

@media screen and (max-width: 720px){
 
}

@media screen and (max-width: 650px){
   
}

@media screen and (max-width: 600px){
    
}

@media screen and (max-width: 390px){

.entertainment-header h4{
    color: white;
    font-size: 35px;
    max-width: 600px;
    line-height: 120%;

}
.entertainment-header p{
    color: rgba(255, 255, 255, 0.762);
    font-size: 15px;
    margin: .5rem 0 ;
}
.entertainment-header button{
    background: rgba(0, 0, 0, 0.69);
    font-size: 15px;
    color: white;
    padding: .5rem 1rem;
    border: none;
    border-radius: 7px;
    margin-top: 1rem;
}

 
}
