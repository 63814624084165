#checkout{
    /* height:100vh; */
    padding-top: 5rem;
}

.shopping-page{
    padding: 2rem;
    font-size: 30px;
}

.shop-cart{
    display: flex;
    justify-content: space-between;
    padding: 0 2rem 2rem;
}
.cart-items{
    width: 65vw;
    border: 1px solid black;
    border-radius: 7px;
    padding: 1rem 2rem;
}
.payment{
    width: 28vw;
    background: rgb(211, 211, 211);
    /* background: rgb(111, 111, 114); */
    padding: 1rem;
    border-radius: 7px;
}
.payment h4{
  padding: 0 0 1rem;
}
.payment-mtd{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.checkout-input, .expiration-data input{
  height: 1rem;
  border: none;
  border-radius: 3px;
}
.checkout-form label{
  font-size: 13px;
  color: rgb(64, 63, 63);
}
.expiration-data{
  display: flex;
  justify-content: space-between;
}
.expiration-data input{
  width: 5rem;
}

.checkout-form button{
  width: 12rem;
  background: #8bc6e4;
  padding: .5rem;
  color: white;
  color: black;
  border: none;
  outline: none;
  margin: 1rem auto;
  margin-inline: auto 0;
  cursor: pointer;
}



/* ------------------------------------------------------------ */
  /* Payout Table */
  .cart-table-label{
    font-weight: 600;
    display: grid;
    grid-template-columns: .1fr 2fr .1fr .2fr .1fr .4fr;
    justify-items: center;
    align-items: center;
    padding: 1px 5px;
    background: #d9d9d9;
    height: 48px;
    width: 60vw;
  }
  .cart-data, .see-more {
    font-weight: 400;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 7px;
    height: 52px;
    width: 60vw;
    border-bottom: 1px solid #EBEBEB;
  }
  
  .cart-data{
    display: grid;
    grid-template-columns: .1fr 2fr .1fr .2fr .1fr .4fr;
    justify-items: center;
    transition: 0.3s ease;
  }
  
  .cart-data:hover{
    border-bottom: 1px solid black;
  }
  .cart-data.total{
    font-size: 18px;
    font-weight: 600;
  }
  .cart-data svg{
    cursor: pointer;
  }

  .empty-cart{
    text-align: center;
    margin-inline: auto;
    margin-block: 1rem;
    font-size: 25px;
  }